/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import {
  Avatar,
  DarkThemeToggle,
  Dropdown,
  Navbar,
} from "flowbite-react";
import {
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi";
import { useSidebarContext } from "./SidebarContext";
import { useAuthStore } from "../stores/auth";
import Logo from "../components/Logo";
import { signOut } from "firebase/auth";
import { auth } from "@class/api/firebase";
import DownloadButton from "@components/DownloadButton";
import { FaDiscord } from "react-icons/fa";

const ExampleNavbar: FC = function () {
  const auth = useAuthStore()
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
    useSidebarContext();

  return (
    <Navbar fluid>
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="mr-3 cursor-pointer rounded p-2 text-white hover:bg-gray-600 lg:inline"
              >
                {isOpenOnSmallScreens
                  ? <HiChevronLeft className="h-6 w-6" />
                  : <HiChevronRight className="h-6 w-6" />
                }
              </button>
            )}
            <Navbar.Brand href="/">
              <Logo />
              <span className="self-center whitespace-nowrap text-2xl text-white dark:text-gray-200">
                Edit Media
              </span>
            </Navbar.Brand>
            {/* <form className="ml-16 hidden md:block">
              <Label htmlFor="search" className="sr-only">
                Search
              </Label>
              <TextInput
                icon={HiSearch}
                id="search"
                name="search"
                placeholder="Search"
                required
                size={32}
                type="search"
              />
            </form> */}
          </div>
          <div className="flex items-center lg:gap-3">
            <div className="flex items-center">
              {/* <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700 dark:focus:ring-gray-700 lg:hidden"
              >
                <span className="sr-only">Search</span>
                <HiSearch className="h-6 w-6" />
              </button>
              <NotificationBellDropdown />
              <AppDrawerDropdown /> */}
              <DownloadButton />

              <a href="https://discord.gg/Uym3W8DYr" target="_blank" className="flex items-center text-white">
                <button className="flex items-center">
                  <FaDiscord className="mx-2" />
                </button>
              </a>

              <DarkThemeToggle />
            </div>
            {auth.user && (
              <div className="block">
                <UserDropdown />
              </div>
            )}
          </div>
        </div>
      </div>
    </Navbar>
  );
};

// const NotificationBellDropdown: FC = function () {
//   return (
//     <Dropdown
//       arrowIcon={false}
//       inline
//       label={
//         <span className="rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700">
//           <span className="sr-only">Notifications</span>
//           <HiBell className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white " />
//         </span>
//       }
//     >
//       <div className="max-w-[24rem]">
//         <div className="block rounded-t-xl bg-gray-50 py-2 px-4 text-center text-base font-medium text-gray-700 dark:bg-gray-700 dark:text-gray-400">
//           Notifications
//         </div>
//         <div>
//           <a
//             href="#"
//             className="flex border-y py-3 px-4 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-600"
//           >
//             <div className="shrink-0">
//               <img
//                 alt=""
//                 src="../images/users/bonnie-green.png"
//                 className="h-11 w-11 rounded-full"
//               />
//               <div className="absolute -mt-5 ml-6 flex h-5 w-5 items-center justify-center rounded-full border border-white bg-primary-700 dark:border-gray-700">
//                 <NewMessageIcon />
//               </div>
//             </div>
//             <div className="w-full pl-3">
//               <div className="mb-1.5 text-sm font-normal text-gray-500 dark:text-gray-400">
//                 New message from&nbsp;
//                 <span className="font-semibold text-gray-900 dark:text-white">
//                   Bonnie Green
//                 </span>
//                 : "Hey, what's up? All set for the presentation?"
//               </div>
//               <div className="text-xs font-medium text-primary-700 dark:text-primary-400">
//                 a few moments ago
//               </div>
//             </div>
//           </a>
//           <a
//             href="#"
//             className="flex border-b py-3 px-4 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-600"
//           >
//             <div className="shrink-0">
//               <img
//                 alt=""
//                 src="../images/users/jese-leos.png"
//                 className="h-11 w-11 rounded-full"
//               />
//               <div className="absolute -mt-5 ml-6 flex h-5 w-5 items-center justify-center rounded-full border border-white bg-gray-900 dark:border-gray-700">
//                 <NewFollowIcon />
//               </div>
//             </div>
//             <div className="w-full pl-3">
//               <div className="mb-1.5 text-sm font-normal text-gray-500 dark:text-gray-400">
//                 <span className="font-semibold text-gray-900 dark:text-white">
//                   Jese Leos
//                 </span>
//                 &nbsp;and&nbsp;
//                 <span className="font-medium text-gray-900 dark:text-white">
//                   5 others
//                 </span>
//                 &nbsp;started following you.
//               </div>
//               <div className="text-xs font-medium text-primary-700 dark:text-primary-400">
//                 10 minutes ago
//               </div>
//             </div>
//           </a>
//           <a
//             href="#"
//             className="flex border-b py-3 px-4 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-600"
//           >
//             <div className="shrink-0">
//               <img
//                 alt=""
//                 src="../images/users/joseph-mcfall.png"
//                 className="h-11 w-11 rounded-full"
//               />
//               <div className="absolute -mt-5 ml-6 flex h-5 w-5 items-center justify-center rounded-full border border-white bg-red-600 dark:border-gray-700">
//                 <NewLoveIcon />
//               </div>
//             </div>
//             <div className="w-full pl-3">
//               <div className="mb-1.5 text-sm font-normal text-gray-500 dark:text-gray-400">
//                 <span className="font-semibold text-gray-900 dark:text-white">
//                   Joseph Mcfall
//                 </span>
//                 &nbsp;and&nbsp;
//                 <span className="font-medium text-gray-900 dark:text-white">
//                   141 others
//                 </span>
//                 &nbsp;love your story. See it and view more stories.
//               </div>
//               <div className="text-xs font-medium text-primary-700 dark:text-primary-400">
//                 44 minutes ago
//               </div>
//             </div>
//           </a>
//           <a
//             href="#"
//             className="flex border-b py-3 px-4 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-600"
//           >
//             <div className="shrink-0">
//               <img
//                 alt=""
//                 src="../images/users/leslie-livingston.png"
//                 className="h-11 w-11 rounded-full"
//               />
//               <div className="absolute -mt-5 ml-6 flex h-5 w-5 items-center justify-center rounded-full border border-white bg-green-400 dark:border-gray-700">
//                 <NewMentionIcon />
//               </div>
//             </div>
//             <div className="w-full pl-3">
//               <div className="mb-1.5 text-sm font-normal text-gray-500 dark:text-gray-400">
//                 <span className="font-semibold text-gray-900 dark:text-white">
//                   Leslie Livingston
//                 </span>
//                 &nbsp;mentioned you in a comment:&nbsp;
//                 <span className="font-medium text-primary-700 dark:text-primary-500">
//                   @bonnie.green
//                 </span>
//                 &nbsp;what do you say?
//               </div>
//               <div className="text-xs font-medium text-primary-700 dark:text-primary-400">
//                 1 hour ago
//               </div>
//             </div>
//           </a>
//           <a
//             href="#"
//             className="flex py-3 px-4 hover:bg-gray-100 dark:hover:bg-gray-600"
//           >
//             <div className="shrink-0">
//               <img
//                 alt=""
//                 src="../images/users/robert-brown.png"
//                 className="h-11 w-11 rounded-full"
//               />
//               <div className="absolute -mt-5 ml-6 flex h-5 w-5 items-center justify-center rounded-full border border-white bg-purple-500 dark:border-gray-700">
//                 <NewVideoIcon />
//               </div>
//             </div>
//             <div className="w-full pl-3">
//               <div className="mb-1.5 text-sm font-normal text-gray-500 dark:text-gray-400">
//                 <span className="font-semibold text-gray-900 dark:text-white">
//                   Robert Brown
//                 </span>
//                 &nbsp;posted a new video: Glassmorphism - learn how to implement
//                 the new design trend.
//               </div>
//               <div className="text-xs font-medium text-primary-700 dark:text-primary-400">
//                 3 hours ago
//               </div>
//             </div>
//           </a>
//         </div>
//         <a
//           href="#"
//           className="block rounded-b-xl bg-gray-50 py-2 text-center text-base font-normal text-gray-900 hover:bg-gray-100 dark:bg-gray-700 dark:text-white dark:hover:underline"
//         >
//           <div className="inline-flex items-center gap-x-2">
//             <HiEye className="h-6 w-6" />
//             <span>View all</span>
//           </div>
//         </a>
//       </div>
//     </Dropdown>
//   );
// };

// const NewMessageIcon: FC = function () {
//   return (
//     <svg
//       className="h-3 w-3 text-white"
//       fill="currentColor"
//       viewBox="0 0 20 20"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
//       <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
//     </svg>
//   );
// };

// const NewFollowIcon: FC = function () {
//   return (
//     <svg
//       className="h-3 w-3 text-white"
//       fill="currentColor"
//       viewBox="0 0 20 20"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path>
//     </svg>
//   );
// };

// const NewLoveIcon: FC = function () {
//   return (
//     <svg
//       className="h-3 w-3 text-white"
//       fill="currentColor"
//       viewBox="0 0 20 20"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
//         clipRule="evenodd"
//       ></path>
//     </svg>
//   );
// };

// const NewMentionIcon: FC = function () {
//   return (
//     <svg
//       className="h-3 w-3 text-white"
//       fill="currentColor"
//       viewBox="0 0 20 20"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
//         clipRule="evenodd"
//       ></path>
//     </svg>
//   );
// };

// const NewVideoIcon: FC = function () {
//   return (
//     <svg
//       className="h-3 w-3 text-white"
//       fill="currentColor"
//       viewBox="0 0 20 20"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
//     </svg>
//   );
// };

// const AppDrawerDropdown: FC = function () {
//   return (
//     <Dropdown
//       arrowIcon={false}
//       inline
//       label={
//         <span className="rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700">
//           <span className="sr-only">Apps</span>
//           <HiViewGrid className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
//         </span>
//       }
//     >
//       <div className="block rounded-t-lg border-b bg-gray-50 py-2 px-4 text-center text-base font-medium text-gray-700 dark:border-b-gray-600 dark:bg-gray-700 dark:text-white">
//         Apps
//       </div>
//       <div className="grid grid-cols-3 gap-4 p-4">
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiShoppingBag className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Sales
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiUsers className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Users
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiInbox className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Inbox
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiUserCircle className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Profile
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiCog className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Settings
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiArchive className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Products
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiCurrencyDollar className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Pricing
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiOutlineTicket className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Billing
//           </div>
//         </a>
//         <a
//           href="#"
//           className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
//         >
//           <HiLogout className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
//           <div className="text-sm font-medium text-gray-900 dark:text-white">
//             Logout
//           </div>
//         </a>
//       </div>
//     </Dropdown>
//   );
// };

const UserDropdown: FC = function () {
  const authStore = useAuthStore()

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span>
          <Avatar
            alt=""
            img={authStore.user.photoURL}
            rounded
            size="sm"
          />
        </span>
      }
    >
      <Dropdown.Header>
        <span className="block text-sm">{authStore.user.displayName}</span>
        <span className="block truncate text-sm font-medium">
          {authStore.user.email}
        </span>
      </Dropdown.Header>
      {/* <Dropdown.Item>Settings</Dropdown.Item> */}
      {/* <Dropdown.Divider /> */}
      <Dropdown.Item onClick={() => signOut(auth)}>Sign out</Dropdown.Item>
    </Dropdown>
  );
};

export default ExampleNavbar;
